<template>
  <g :fill="color" fill-rule="evenodd">
    <path
      fill-rule="nonzero"
      d="M4.13 13.937l-.514-2.124H2.01c-.696 0-1.26-.56-1.26-1.246V1.996C.75 1.307 1.312.75 2.017.75h10.03c.702 0 1.267.561 1.267 1.252V7.48a.75.75 0 0 0 1.5 0V2.002A2.759 2.759 0 0 0 12.048-.75H2.017A2.753 2.753 0 0 0-.75 1.996v8.571a2.754 2.754 0 0 0 2.76 2.746h.426l.323 1.334c.27 1.115 1.41 1.42 2.203.59l1.842-1.924h.954a.75.75 0 1 0 0-1.5H6.163L4.13 13.937z"
    />
    <rect width="9.057" height="1.5" x="2.516" y="3.5" rx=".75" />
    <rect width="9.057" height="1.5" x="2.516" y="7" rx=".75" />
  </g>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
